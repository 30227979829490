import { direccion } from '../_extra/configuracion'

const state = {
    all: {},
    cajas: [],
    cajausuarios: [],
    cajasporusuario: [],
    respCaja: '',
    idseleccion: -1,
    nombreseleccion: ''
}

const actions = {
    async listarCajas({commit}) {
        const opciones = {
            method: 'GET',
        }
        try {
            let response = await fetch(`${direccion().url}/caja.php?factor=fa01`, opciones)
            const contenido = await response.json()
            commit('obtenerTodoCajas', contenido)
        } catch (error) {
            //console.error(error)
        }
    },
    async listarCajasActivas({commit}) {
        const opciones = {
            method: 'GET',
        }
        try {
            let response = await fetch(`${direccion().url}/caja.php?factor=fa05`, opciones)
            const contenido = await response.json()
            commit('obtenerCajasActivas', contenido)
        } catch (error) {
            //console.error(error)
        }
    },
    async listarCajaUsuarios({commit}, idCaja) {
        const opciones = {
            method: 'GET',
        }
        try {
            let response = await fetch(`${direccion().url}/caja.php?factor=fa02&id=${idCaja}`, opciones)
            const contenido = await response.json()
            commit('obtenerTodoCajaUsuarios', contenido)
        } catch (error) {
            //.error(error)
        }
    },
    async listarCajasPorUsuario({commit}, idUsuario) {
        const opciones = {
            method: 'GET',
        }
        try {
            let response = await fetch(`${direccion().url}/caja.php?factor=fa03&idusuario=${idUsuario}`, opciones)
            const contenido = await response.json()
            //console.log(contenido)
            commit('obtenerTodoCajasPorUsuario', contenido)
        } catch (error) {
            //console.error(error)
        }
    },
    async agregar({commit}, nuevaCajas) {
        const opciones = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(nuevaCajas)
        }
        try {
            let response = await fetch(`${direccion().url}/caja.php?accion=a01`, opciones)
            const contenido = await response.json()
            commit('agregarCajas', contenido)
        } catch (error) {
            //console.error(error)
        }
    },
    async editar({commit}, cajasEditar) {
        const opciones = {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(cajasEditar)
        }
        try {
            await fetch(`${direccion().url}/caja.php?id=${cajasEditar.id}`, opciones)
            commit('editarCajas', cajasEditar);
        } catch (error) {
            //console.error(error)
        }
    },
    async eliminar({commit}, idCajas) {
        const opciones = {
            method: 'DELETE'
        }
        try {
            let response = await fetch(`${direccion().url}/caja.php?accion=de01&id=${idCajas}`, opciones)
            const resultado = await response.json()
            commit('eliminarCajas', resultado.coderror) 
        } catch (error) {
            //console.error(error)
        }
    },
    async eliminarCajaUsuario({commit}, idCajaUsuarios) {
        const opciones = {
            method: 'DELETE'
        }
        try {
            let response = await fetch(`${direccion().url}/caja.php?accion=de02&id=${idCajaUsuarios}`, opciones)
            const resultado = await response.json()
            commit('eliminarCajaUsuarios', resultado.coderror) 
        } catch (error) {
            //console.error(error)
        }
    },
    async asignarusuario({commit}, cajasEditar) {
        const opciones = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(cajasEditar)
        }
        try {
            let response = await fetch(`${direccion().url}/caja.php?accion=a02`, opciones)
            commit('asignarusuarioCajas', response);
        } catch (error) {
           // console.error(error)
        }
    },
    actualizarIdCaja({commit}, valor){
        //console.log(valor)
        commit('modificarIdCaja', valor)
    }
}

const mutations = {
    obtenerTodoCajas(state, cajas) {
        state.all = {items: cajas}
        state.cajas = cajas
    },
    obtenerCajasActivas(state, cajas) {
        state.all = {items: cajas}
        state.cajas = cajas
    },
    obtenerTodoCajaUsuarios(state, cajas) {
        state.all = {items: cajas}
        state.cajas = cajas
        //state.idseleccion = cajausuarios.id
    },
    obtenerTodoCajasPorUsuario(state, cajasporusuario) {
        state.cajasporusuario = cajasporusuario
        state.idseleccion = cajasporusuario[0].idcaja
        state.nombreseleccion = cajasporusuario[0].nombrecaja
    },
    errorObtenerCajas(state, error) {
        state.all = { error }
    },  
    agregarCajas(state, cajas) {
        state.all = {id: cajas.id}
    },
    eliminarCajas(state, response) {
        state.respCaja = response
    },
    eliminarCajaUsuarios(state, coderror) {
        state.all = {respuestaD: coderror}
    },
    editarCajas(state, editarCajas) {
        state.all = {item: editarCajas}
    },
    asignarusuarioCajas(state, response) {
        state.respCaja = response
    },
    modificarIdCaja(state, valor){
        state.idseleccion = valor.idcaja
        state.nombreseleccion = valor.nombrecaja
        //console.log(state.nombreseleccion)
    }
}

export const caja = {
    namespaced: true,
    state,
    actions,
    mutations
}