import Vue from 'vue'
import Vuex from 'vuex'

import { alerta } from './Alerta.modulo'
import { usuario } from './Usuario.modulo'
import { caja } from './Caja.modulo'
import { movimiento } from './Movimiento.modulo'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: false,
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    alerta,
    usuario,
    caja,
    movimiento
  }
})
