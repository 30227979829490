<template>
    <div>
        <v-container style="padding-left:70px;" fluid>
            <v-row>
                <v-col cols="12" class="mb-0 pb-0">
                    <div class="text-h6 mt-5 mb-3">Movimientos de caja del día</div>
                </v-col>
                
                <v-col cols="12" md="3">
                    
                    <div class="d-inline-flex">
                        <v-dialog
                            ref="dialog"
                            v-model="modalfecha"
                            :return-value.sync="date"
                            :first-day-of-week="0"
                            persistent
                            width="290px"
                            
                        >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="computedDateFormatted"
                            label="Fecha de consulta"
                            prepend-icon="mdi-calendar"
                            persistent-hint
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                        v-model="date"
                        scrollable
                        :first-day-of-week="1"
                        locale="es-es"

                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="modalfecha = false"
                        >
                            Cancelar
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(date); fechadatos();"
                        >
                            Aplicar
                        </v-btn>
                        </v-date-picker>
                    </v-dialog>
                    <v-select class="mt-3 pl-5"
                        v-model="cajapordefecto"
                        :items="cajas"
                        :item-text="'nombrecaja'"
                        :item-value="'id'"
                        label="Seleccione Caja"
                        dense
                        outlined
                        style="max-width: 250px; margin-bottom:-30px;"
                        background-color="white"
                        solo
                        light
                        @change="seleccionarCaja"
                        >
                            <template v-slot:append-outer>
                                <v-btn
                                class="mt-0"
                                fab
                                dark
                                color="orange"
                                style="top: -15px;"
                                @click="actualizarDatos()"
                                >
                                    <v-icon dark>
                                        mdi-refresh
                                    </v-icon>
                                </v-btn>
                                </template>
                        </v-select>
                        
                    </div>
                    
                </v-col>
                <v-col cols="6" md="2" >
                    <v-text-field 
                        class="ml-2 "
                        v-model.number="margentext" 
                        label="Margen" 
                        append-outer-icon="mdi-plus-circle" 
                        @click:append-outer="incremento" 
                        prepend-icon="mdi-minus-circle" 
                        @click:prepend="decremento">
                        </v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-data-table
                        :headers="headerMovimientos"
                        :items="movimientos"
                        item-key="orden"
                        class="elevation-1"
                        height="55vh"
                        fixed-header
                        :hide-default-footer="true"
                        dark
                        :header-props="headerProps"
                        disable-pagination
                        no-data-text="No hay registros"
                        :loading="cargaData"
                        loading-text="Cargando datos... Por favor espere"
                    >
                        <template v-slot:[`item.imagen`]="{ item }" >
                            <v-img :src="'data:image/jpeg;base64,' + item.imagen" :width="20"></v-img>
                        </template>
                        <template v-slot:[`item.concepto`]="{ item }">
                            <v-chip
                                :color="getColor(item.accion)"
                                dark
                                v-if="item.accion == 'DE' || item.accion == 'RE' || item.accion == 'PR' || item.accion == 'AM'
                                || item.accion == 'ZD' || item.accion == 'ZR' || item.accion == 'ZP' || item.accion == 'ZA'"
                            >
                                {{ item.concepto }}
                            </v-chip>
                            <span v-else>{{ item.concepto }}</span>
                        </template>
                        <template v-slot:[`item.tipocambio`]="{ item }">
                            <template
                                v-if="item.accion == 'PC' || item.accion == 'ZC'"
                            >
                                <span v-if="item.tipocambio <= (margentext + item.tipocambio) && item.tipocambio >= (item.tipocambio - margentext)">
                                    <span class="text--white">{{ item.tipocambio }}</span>
                                </span>
                                <span v-else>
                                    <span class="text--red">{{ item.tipocambio }}</span>
                                </span>
                            </template>
                            <span v-else>
                                    <span class="red--text">{{ item.tipocambio }}</span>
                                </span>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12" 
                    md="4"
                    sm="6"
                    lg="3"
                    v-for="item in saldos"
                    :key="item.idmoneda"
                >
                    <v-card
                        class="mx-auto"
                        outlined
                        dark
                    >
                    <v-card-title class="pt-0 pb-0">
                        <img
                            width="20"
                            height="20"
                            :src="'data:image/jpeg;base64,' + item.imagen"
                            class="mx-0"
                        >
                        <p class="ml-3 pt-3" >
                        {{item.nombremoneda}}
                        </p>
                    </v-card-title>
                    <v-divider class="mx-4"></v-divider>
                    <v-list>
                        <v-list-item>
                            <v-list-item-title>Saldo Inicial</v-list-item-title>
                            <v-list-item-subtitle class="text-right">
                                <v-chip
                                class="ma-2"
                                color=" text-body-1 "
                                >
                                {{ numeroComas(parseFloat(item.inicial).toFixed(2))}}
                                </v-chip>
                            </v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>Ingresos</v-list-item-title>
                            <v-list-item-subtitle class="text-right">
                                <v-chip
                                class="ma-2"
                                color="primary text-body-1 "
                                >
                                {{ numeroComas(parseFloat(item.ingreso).toFixed(2)) }}
                                </v-chip>
                            </v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>Egresos</v-list-item-title>
                            <v-list-item-subtitle class="text-right">
                                <v-chip
                                class="ma-2"
                                color="red text-body-1 white--text"
                                >
                                {{ numeroComas(parseFloat(item.egreso).toFixed(2)) }}
                                </v-chip>
                            </v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>Saldo Final</v-list-item-title>
                            <v-list-item-subtitle class="text-right">
                                <v-chip
                                class="ma-2 text-body-1 font-weight-medium "
                                color=""
                                >
                                {{ numeroComas(parseFloat(item.final).toFixed(2)) }}
                                </v-chip>
                            </v-list-item-subtitle>
                        </v-list-item>
                    </v-list>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>

import {mapState, mapActions} from 'vuex'

export default {
    name: 'Movimientos',
    components: {
    },
    data: () => (
        {
            margentext : 0,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            cargaData: false,
            modalfecha: false,
            headerMovimientos: [
                { text: 'Nro', sortable: true, value: 'orden'},
                { text: 'Tipo', sortable: true, value: 'tipo' },
                { text: 'Accion', value: 'accion', align: ' d-none' },
                { text: 'Codigo', sortable: true, value: 'codigo' },
                { text: 'Cliente', sortable: true, value: 'ejecutante' },
                { text: 'Concepto', sortable: true, value: 'concepto' },
                { text: 'Fecha', sortable: true, value: 'fecha' },
                { text: 'Ingreso S/', sortable: true, value: 'ingreso_s', align: 'end'},
                { text: 'Egreso S/', sortable: true, value: 'egreso_s', align: 'end' },
                { text: 'Ingreso $', sortable: true, value: 'ingreso_m', align: 'end' },
                { text: 'Egreso $', sortable: true, value: 'egreso_m', align: 'end' },
                { text: 'Tipo cambio', sortable: true, value: 'tipocambio', align: 'end' },
                { text: 'idmoneda', sortable: true, value: 'moneda',  align: ' d-none' },
                //{ text: 'Im', sortable: true, false: 'moneda' },
                { text: 'Im', sortable: true,  value: 'imagen' },
                { text: 'Moneda', sortable: true, value: 'nombremoneda' },
            ],
            editandoItem: {
                nombrecaja: ''
            },
            inicioItem: {
                nombrecaja: ''
            },
            cajapordefecto: 1,
            headerProps: {
                sortByText: "Ordenar por ..."
            },
        }
    ),
    async created() {
        const margenes = JSON.parse(localStorage.getItem('datos'))

         if (!margenes) {
             const datos = {
                 margen : 0.05
             }
              localStorage.setItem('datos', JSON.stringify(datos))
        }else {
            
            this.margentext = margenes.margen
        }

        if(this.idseleccion <= -1) {
            if(this.usuarios.cargo === "administrador"){
                await this.listarCajasActivas()
            } else{
                await this.listarCajaUsuarios(this.usuarios.id)
            }
          this.cajapordefecto = this.cajas[0].id
        }
        await this.cargarMovmientos()
    },
    computed: {
        ...mapState('usuario', ['usuarios']),
        ...mapState('movimiento', ['movimientos', 'saldos']),
        ...mapState('caja', ['cajas', 'idseleccion']),
        computedDateFormatted () {
            return this.formatDate(this.date)
        },
    },
    methods: {
        ...mapActions('movimiento', ['listarMovimientos', 'listarSaldosFecha', 'listarComprasSaldo']),
        ...mapActions('caja', ['listarCajasActivas','actualizarIdCaja', 'listarCajaUsuarios']),
        async cargarMovmientos() {
            this.cargaData = true
            var datos = {
                fecha: this.date,
                idcaja: this.cajapordefecto
            }
            await this.listarMovimientos(datos);
            this.cargaData = false
            await this.listarSaldosFecha(datos);
            
            
        },
       verMovimiento(item){
           //console.log(item.accion)
           this.cambiarIdMovmiento(item.codigo.substring(3))
           if(item.accion == 'entrada') {
               this.$refs.customModalmov.mostrarMov()
                this.dialogIE = true
               
           } else if (item.accion == 'deposito') {
                this.$refs.customModalDeposito.mostrarMov()
                this.dialogIE = true
           } else {
                console.log("hola")
           }
       },
       async seleccionarCaja(item){
         //console.log(item)
          this.actualizarIdCaja(item)
          await this.cargarMovmientos()
          //console.log(this.cajas)
          
        },
        async actualizarDatos()
        {
            var datos = {
                fecha: this.date,
                idcaja: this.cajapordefecto
            }
            this.cargaData = true
            await this.listarMovimientos(datos);
            this.cargaData = false
            await this.listarSaldosFecha(datos);
        },
        numeroComas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },
        parseDate (date) {
            if (!date) return null

            const [month, day, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        async fechadatos()
        {
            await this.actualizarDatos()
        },
        getColor (accion) {
            let color = ''
            switch (accion) {
                case 'DE':
                case 'ZD':
                    color = 'blue'
                    break;
                case 'PR':
                case 'ZP':
                    color = 'orange'
                    break;
                case 'RE':
                case 'ZR':
                    color = 'red'
                    break;
                default:
                    color = 'green'
                    break;
            }
            return color
        },
        incremento(){
            this.margentext =  (parseFloat(this.margentext) + 0.01).toFixed(2)
            const datos = {
                 margen : this.margentext
             }
              localStorage.setItem('datos', JSON.stringify(datos))
        },
        decremento(){
            if( this.margentext > 0)
                this.margentext =  (parseFloat(this.margentext) - 0.01).toFixed(2)
            const datos = {
                 margen : this.margentext
             }
              localStorage.setItem('datos', JSON.stringify(datos))
        },
    },
    watch: {
        date () {
            this.dateFormatted = this.formatDate(this.date)
        },
    }
}
</script>
<style>
.abajo-items > .v-data-table__wrapper{
      overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
}
</style>