import router from '../router'
import { direccion } from '../_extra/configuracion'

const usuarios = JSON.parse(localStorage.getItem('usuario'))

const state = usuarios 
    ? { estado: { logueado: true }, usuarios,  usuariosele:null }
    : { estado: {}, usuarios:null, usuariosele:null }

const actions = {
    async login ({dispatch, commit},{usuario, contrasena}) {
        commit('loginPedido', {usuario})
        const pedidoOpciones = {
            method: 'POST',
            headers: { 'Content-type':'application/json' },
            body: JSON.stringify({ usuario, contrasena})
        }
        try {
            let respuesta = await fetch(`${direccion().url}/usuarios.php?accion=autenticarweb`, pedidoOpciones)
            const contenido = await respuesta.json()
            //console.log(contenido)
            if(contenido.nombres != null) {
                localStorage.setItem('usuario', JSON.stringify(contenido))
                commit('loginExitoso', contenido)
                router.push('/')
            } else {
                commit('loginFallido', 'Usuario y/o contraseña incorrectos')
                dispatch('alerta/error', 'Usuario y/o contraseña incorrectos', {root:true})
            }
        } catch (error) {
            //console.error(error)
        }
    },
    logout({commit}) {
        localStorage.removeItem('usuario')
        console.log("primer: " + state.estado.logueado)
        commit('logout')
    },
    async listarusuarioPorId({commit}, idusuario) {
        const opciones = {
            method: 'GET',
        }
        try {
            let respuesta = await fetch(`${direccion().url}/usuarios.php?id=${idusuario}`, opciones)
            const contenido = await respuesta.json()
            commit('obtenerusuarioPorId', contenido)
        } catch (error) {
            //console.error(error)
        }
    },
    async editar({commit}, elementoEditar) {
        const opciones = {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(elementoEditar)
        }
        try {
            console.log(elementoEditar.id)
            await fetch(`${direccion().url}/usuarios.php?id=${elementoEditar.id}`, opciones)
            commit('editarusuario', elementoEditar);
        } catch (error) {
            //console.error(error)
        }
    },
}

const mutations = {
    loginPedido(state, usuario) {
        state.usuarios = usuario
    },
    loginExitoso(state, usuario) {
        state.estado = {logueado:true}
        state.usuarios = usuario
    },
    loginFallido(state) {
        state.estado = {}
        state.usuarios = null
    },
    logout(state) {
        //state.estado = { logueado = false}
        state.usuarios = null
        state.estado.logueado = false
        console.log("segundo:" + state.estado.logueado)
    },
    obtenerusuarioPorId(state, usuario) {
        state.usuariosele = usuario
    },
    editarusuario(state, usuario) {
        state.usuarios = usuario
    }
}

export const usuario = {
    namespaced: true,
    state,
    actions,
    mutations
}