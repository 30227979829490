<template>
<v-container  fluid ma-0 pa-0 fill-heigh>
    <v-card color="grey lighten-4" flat height="50px" tile>
     <v-app-bar
      color="orange darken-3"
      dense
      dark
      fixed
    >
        <v-app-bar-nav-icon @click.stop="estadoBar"></v-app-bar-nav-icon>

        <v-toolbar-title>Sistema Cambios en Linea</v-toolbar-title>

        <v-spacer></v-spacer>
        <!--<v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            color="green"
            content="1"
            overlap
            bottom
          >
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="mx-2"
              fab
              dark
              small
              color="primary"
            >
              <v-icon dark>
                mdi-bell
              </v-icon>
            </v-btn>
          </v-badge>
        </template>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon class="blue lighten-1 white--text">
                  mdi-currency-usd
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>Agregado el reporte de utilidades por día</v-list-item-title>

                <v-list-item-subtitle>20/08/2021</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
              <v-btn icon href="/utilidades">
                <v-icon color="gree lighten-1">
                  mdi-exit-to-app
                </v-icon>
              </v-btn>
            </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-menu>-->
        <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
            dark
            v-bind="attrs"
            v-on="on"
            icon
            >
            <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
        </template>
        <v-list>
            <v-list-item
            @click="cerrarSesion"
            >
            <v-list-item-title>Cerrar Sesión</v-list-item-title>
            </v-list-item>
        </v-list>
        </v-menu>
     </v-app-bar>
    </v-card>

    <v-navigation-drawer
      v-model="drawer"
      :color="color"
      :mini-variant="miniVariant"
      :right="right"
      :src="bg"
      clipped
      class="mt-5"
      style="top: 30px"
      :permanent="!permanent"
      fixed
      app 
    >
      <v-list dense nav class="py-0">
        <v-list-item two-line :class="miniVariant && 'px-0'">
          <v-list-item-avatar>
            <v-icon>mdi-face</v-icon>
          </v-list-item-avatar>

          <v-list-item-content v-if="estado.logueado"> 
            <v-list-item-title>{{cemail}}</v-list-item-title>
            <v-list-item-subtitle>{{cnombres}} {{capellidos}} </v-list-item-subtitle>
            <v-list-item-subtitle class="green--text font-weight-medium">{{ccargo}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-list-item v-for="item in items" :key="item.title" link :to="item.ruta">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-icon v-bind="attrs" v-on="on" >
                <v-icon >{{ item.icon }}</v-icon>
              </v-list-item-icon>
            </template>
            <span>{{item.title}}</span>
          </v-tooltip>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>
</v-container>
</template>

<script>

import { mapState, mapActions} from 'vuex'

export default {
    name: "Barmenu",
    data() {
    return {
            drawer: true,
            items: [
            { title: "Inicio", icon: "mdi-home", ruta: "/" },   
            ],
            color: "white",
            colors: ["orange"],
            right: false,
            permanent: false,
            miniVariant: true,
            background: false,
            cnombres: '',
            capellidos: '',
            ccargo: '',
            cemail: '',
            idusuario: ''
        };
    },
    computed: {
        bg() {
            return this.background
            ? "https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg"
            : undefined;
        },
         ...mapState('usuario', ['usuarios', 'estado'])
    }, 
    created () {
        //console.log(this.user)
        if(this.estado.logueado){
          this.cemail = this.usuarios.dni
          this.cnombres = this.usuarios.nombres
          this.capellidos = this.usuarios.apellidos
          this.ccargo = this.usuarios.cargo
        }
    },
    methods: {
      ...mapActions('usuario', ['logout']),
        estadoBar() {
            this.miniVariant = !this.miniVariant
            this.showapp = !this.showapp
        },
        async cerrarSesion() {
            await this.logout()
            this.$router.push('/login')
        }
    }
}
</script>