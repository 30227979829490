<template>
    <div class="login">
        <v-container fluid fill-height>
            <v-row justify="center" align="center">
                <v-col cols="12" sm="8" md="4">
                    <v-card class="elevation-12">
                        <v-card-title color="primary">Iniciar sesión - administrador</v-card-title>
                        <v-form ref="form" v-model="validar" @submit.prevent="iniciarsesion" id="loginform" >
                            <v-card-text>
                                <v-text-field label="Usuario" v-model="usuario"  :rules="usuarioReglas" prepend-icon="mdi-account" type="text" v-on:click="onChange"></v-text-field>
                                <v-text-field label="Contraseña" v-model="contrasena" :rules="contrasenaReglas" prepend-icon="mdi-lock"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="show1 ? 'text' : 'password'"
                                @click:append="show1 = !show1"
                                ></v-text-field>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn type="submit" form="loginform" color="primary">Iniciar</v-btn>
                            </v-card-actions>
                        </v-form>
                        <v-alert v-if="alerta.message" :type="alerta.type">
                            {{alerta.message}}
                        </v-alert>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
export default {
    data () {
        return {
            validar: true,
            usuario: '',
            usuarioReglas: [
                v => !!v || 'Ingrese el Usuario',
            ],
            contrasena: '',
            contrasenaReglas: [
                v => !!v || 'Ingrese la contraseña'
            ],
            show1: false
        }
    },
    computed: {
        ...mapState({alerta: state => state.alerta}),
        ...mapState('usuario', ['estado', 'usuarios'])
    },
    created () {
        //console.log('aqui')
    },
    methods: {
        ...mapActions('usuario', ['login', 'logout'], {clearAlert: 'alert/clear'}),
        iniciarsesion () {
            if(this.$refs.form.validate()) {
                const { usuario, contrasena } = this
                if( usuario && contrasena ) {
                    this.login ({ usuario, contrasena })
                } 
            }           
        },
        onChange() {
            this.alerta.message = null
        }
    },
    watch: {

    } 
}
</script>