<template>
  <div class="home">
    <!--<HelloWorld msg="Welcome to Your Vue.js App"/>-->
    <inicio-vista style="padding-left:80px;"/>

  </div>
</template>

<script>
// @ is an alias to /src

import InicioVista from '../components/Inicio.vista.vue'

export default {
  name: 'Home',
  components: {
    InicioVista
  }
}
</script>
